import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";

// styles

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

// mouting point for the whole app

import App from "@/App.vue";


// views without layouts

import Index from "@/views/Index.vue";
import Project from "@/views/Project.vue";

// routes

const routes = [
  {
    path: "/projects",
    redirect: "/",
    component: Index,
    children: [
      {
        path: "/projects/:slug",
        component: Project,
      },
    ],
  },
  {
    path: "/",
    component: Index,
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

createApp(App).use(router).mount("#app");
